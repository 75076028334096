import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./styles.css";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface IMediaCenterDetailForm {
  isEdit?: boolean;
  selectedMediaCenter?: any;
  setMediaCenterDetail?: any;
  isEditDetail?: boolean;
}

const MediaCenterDetailForm: React.FC<IMediaCenterDetailForm> = ({
  selectedMediaCenter,
  isEdit,
  setMediaCenterDetail,
  isEditDetail,
}) => {
  const [uploadedImage, setUploadedImage] = useState<any>(
    selectedMediaCenter?.image ? selectedMediaCenter?.image : ""
  );
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles?.forEach((file: File) => {
        if (file) {
          setMediaCenterDetail((prev: any) => ({
            ...prev,
            image: file,
          }));
          const reader = new FileReader();
          reader.onloadend = () => {
            // After reading the file, set the uploaded image in the state
            setUploadedImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
      });
    },
    [selectedFiles]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const [videoUrl, setVideoUrl] = useState<any>(null);

  useEffect(() => {
    if (selectedMediaCenter?.image instanceof File) {
      // Create URL if `image` is a File object
      const fileUrl = URL.createObjectURL(selectedMediaCenter.image);
      setVideoUrl(fileUrl);

      // Clean up the URL when the component unmounts or the file changes
      return () => URL.revokeObjectURL(fileUrl);
    } else {
      // Use image URL directly if it's a string link
      setVideoUrl(selectedMediaCenter?.image);
    }
  }, [selectedMediaCenter]);

  return (
    <Col>
      <Row>
        <Col sm={12} lg={2} md={12}>
          {selectedMediaCenter?.image ? (
            selectedMediaCenter?.media_type === "VIDEO" ? (
              <div
                style={{
                  position: "relative",
                  width: "200px",
                  height: "200px",
                }}
              >
                {/* <video width="200" height="200" controls>
                  <source src={selectedMediaCenter?.image} type="video/mp4" />
                </video> */}
                {selectedMediaCenter?.media_type === "VIDEO" && videoUrl && (
                  <video width="200" height="200" controls>
                    <source src={videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
                {!isEdit && isEditDetail && (
                  <label
                    htmlFor="video-file-input"
                    style={{
                      position: "absolute",
                      bottom: "0px",
                      right: "10px",
                      color: "red",
                      cursor: "pointer",
                    }}
                  >
                    <FormattedMessage id="edit" />
                  </label>
                )}
                <input
                  type="file"
                  id="video-file-input"
                  accept="video/*"
                  data-testid="video-file-input"
                  {...getInputProps()}
                />
                {/* <input
                  type="file"
                  accept="video/*"
                  style={{ display: "none" }}
                  onChange={(e:any) => {
                    // Handle the new video upload
                    const file = e?.target?.files[0];
                    if (file) {
                      // Process the uploaded video file
                      console.log("New video selected:", file);
                    }
                  }}
                /> */}
              </div>
            ) : !isEdit && isEditDetail ? (
              <div
                style={{
                  background: `url(${uploadedImage})`,
                  height: "inherit",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                  width: "100%",
                  borderRadius: "8px",
                }}
              >
                <input
                  type="file"
                  id="file-input"
                  data-testid="file-input"
                  style={{ width: "100%", height: "100%" }}
                  {...getInputProps()}
                />
                <label htmlFor="file-input">
                  <span
                    style={{
                      paddingRight: "10px",
                      paddingBottom: "10px",
                      color: "red",
                      cursor: "pointer",
                    }}
                  >
                    <FormattedMessage id="edit" />
                  </span>
                </label>
              </div>
            ) : (
              <div>
                <img className="image-div" src={uploadedImage} alt="Logo" />
              </div>
            )
          ) : (
            <div
              style={{
                width: "100%",
                height: "170px",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  background: "rgba(0, 0, 0, 0.50)",
                  height: "inherit",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                {...getRootProps()}
              >
                <input
                  type="file"
                  id="file-input"
                  data-testid="file-input"
                  {...getInputProps()}
                />
                <FiUpload color="white" size={30} />
                <label htmlFor="file-input"></label>
              </div>
            </div>
          )}
        </Col>

        {/* <Col sm={12} lg={2} md={12}>
          {selectedMediaCenter?.image ? (
            selectedMediaCenter?.media_type == "VIDEO" ? (
              <video width="200" height="200" controls>
                <source src={selectedMediaCenter?.image} type="video/mp4" />
              </video>
            ) : !isEdit && isEditDetail ? (
              <div
                style={{
                  background: `url(${uploadedImage})`,
                  height: "inherit",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                  width: "100%",
                  borderRadius: "8px",
                }}
                // {...getInputProps()}
              >
                <input
                  type="file"
                  id="file-input"
                  data-testid="file-input"
                  style={{ width: "100%", height: "100%" }}
                  {...getInputProps()}
                />
                <label htmlFor="file-input">
                  <span
                    style={{
                      paddingRight: "10px",
                      paddingBottom: "10px",
                      color: "red",
                      cursor: "pointer",
                    }}
                  >
                    Edit
                  </span>
                </label>
              </div>
            ) : (
              <div>
                <img className="image-div" src={uploadedImage} alt="Logo" />
              </div>
            )
          ) : (
            <div
              style={{
                width: "100%",

                height: "170px",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  background: "rgba(0, 0, 0, 0.50)",
                  height: "inherit",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                {...getRootProps()}
              >
                <input
                  type="file"
                  id="file-input"
                  data-testid="file-input"
                  {...getInputProps()}
                />

                <FiUpload color="white" size={30} />
                <label htmlFor="file-input"></label>
              </div>
            </div>
          )}
        </Col> */}
        <Col sm={12} lg={4} md={12}>
          <div style={{ padding: "5px" }}>
            <p className="employee-title">
              <FormattedMessage id="id" />
            </p>
            <input
              type="text"
              value={selectedMediaCenter?.id}
              disabled
              className="employee-form-input"
            />
          </div>
          <div style={{ padding: "5px" }}>
            <p className="employee-title">
              <FormattedMessage id="type" />
            </p>
            <select
              disabled={isEdit}
              className="employee-form-input"
              size={1}
              onChange={(e) =>
                setMediaCenterDetail((prev: any) => ({
                  ...prev,
                  media_type: e.target.value,
                }))
              }
              placeholder="Select Media Type"
              value={selectedMediaCenter?.media_type}
            >
              <option selected disabled>
                Select Type
              </option>
              <option value={"EVENT"}>Event</option>
              <option value={"VIDEO"}>Video</option>
              <option value={"NEWS"}>News</option>
            </select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="title" />
            </p>
            <input
              type="text"
              value={selectedMediaCenter?.title}
              disabled={isEdit}
              onChange={(e) => {
                setMediaCenterDetail((prev: any) => ({
                  ...prev,
                  title: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="title_ar" />
            </p>
            <input
              type="text"
              value={selectedMediaCenter?.title_ar}
              disabled={isEdit}
              onChange={(e) => {
                setMediaCenterDetail((prev: any) => ({
                  ...prev,
                  title_ar: e.target.value,
                }));
              }}
              className="employee-form-input"
              style={{ textAlign: "right" }}
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="description" />
            </p>
            <ReactQuill
              value={selectedMediaCenter?.description}
              readOnly={isEdit}
              onChange={(value) => {
                setMediaCenterDetail((prev: any) => ({
                  ...prev,
                  description: value,
                }));
              }}
              className="quill-editor"
              modules={{
                toolbar: [
                  // container:"border-2",
                  ["bold", "italic", "underline", "strike"], // toggled buttons
                  ["blockquote", "code-block"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  //  [{ 'direction': 'rtl' }],
                  ["clean"], // remove formatting button
                ],
              }}
            />
            {/* <input
              type="text"
              value={selectedMediaCenter?.description}
              disabled={isEdit}
              onChange={(e) => {
                setMediaCenterDetail((prev: any) => ({
                  ...prev,
                  description: e.target.value,
                }));
              }}
              className="employee-form-input"
            /> */}
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="description_ar" />
            </p>
            <ReactQuill
              value={selectedMediaCenter?.description_ar}
              className="quill-editor rtl-quill-editor"
              onChange={(value) => {
                setMediaCenterDetail((prev: any) => ({
                  ...prev,
                  description_ar: value,
                }));
              }}
              modules={{
                toolbar: [
                  // container:"border-2",
                  ["bold", "italic", "underline", "strike"], // toggled buttons
                  ["blockquote", "code-block"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  //  [{ 'direction': 'rtl' }],
                  ["clean"], // remove formatting button
                ],
              }}
            />
            <style>{`
                .rtl-quill-editor .ql-editor {
                  direction: rtl;
                  text-align: right;
                }
              `}</style>
            {/* <input
              type="text"
              value={selectedMediaCenter?.description_ar}
              disabled={isEdit}
              onChange={(e) => {
                setMediaCenterDetail((prev: any) => ({
                  ...prev,
                  description_ar: e.target.value,
                }));
              }}
              className="employee-form-input"
              style={{ textAlign: "right" }}
            /> */}
          </div>
        </Col>
      </Row>
    </Col>
  );
};
export default MediaCenterDetailForm;
