import { Button, Dropdown, Input, MenuProps, Space, message } from "antd";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./style.css";
import SettingSearchIcon from "../../assets/svg/SettingSearchIcon";
import LinesIcon from "../../assets/svg/LinesIcon";
import CopyCloseIcon from "../../assets/svg/CopyCloseIcon";
import DashboardIcon from "../../assets/svg/DashboardIcon";
import OpenCloseSIderIcon from "../../assets/svg/OpenCloseSider";
import Polygon from "../../assets/icons/Polygon.png";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import { LanguageContext } from "../../context/LanguageContext";

interface ITableHeader {
  isSkelton?: boolean;
  setIsRightSidebar?: any;
  isRightSidebar?: boolean;
  setLeftWidth?: Dispatch<SetStateAction<string>>;
  width?: number;
  selectedLayout?: string;
  setSelectedLayout?: Dispatch<SetStateAction<string>>;
  setIsCopyClicked?: Dispatch<SetStateAction<boolean>>;
  isCopyClicked?: boolean;
  isAdmin: boolean;
  title: any;
  numberOfResults: number;
  handleMenuClick?: any;
  handleSearch?: any;
  selectedPage?: any;
}
const TableHeader: React.FC<ITableHeader> = ({
  isSkelton,
  width,
  setIsRightSidebar,
  isRightSidebar,
  setLeftWidth,
  selectedLayout,
  setSelectedLayout,
  isCopyClicked,
  setIsCopyClicked,
  isAdmin,
  title,
  numberOfResults,
  handleMenuClick,
  handleSearch,
  selectedPage,
}) => {
  const intl = useIntl();
  useEffect(() => {
    if (isCopyClicked) {
      setSelectedLayout && setSelectedLayout("copy");
    } else {
      if (!isAdmin) {
        if (width) {
          if (width < 53) {
            setSelectedLayout && setSelectedLayout("card");
          } else {
            setSelectedLayout && setSelectedLayout("table");
          }
        }
      }
    }
  }, [width]);

  const { locale } = useContext(LanguageContext);

  const items: MenuProps["items"] = [
    {
      label: <FormattedMessage id="employees" />,
      key: "1",
    },
    {
      label: <FormattedMessage id="requesters" />,
      key: "2",
    },
    {
      label: <FormattedMessage id="departments" />,
      key: "3",
    },
    {
      label: <FormattedMessage id="lists" />,
      key: "4",
    },
    {
      label: <FormattedMessage id="plants" />,
      key: "5",
    },
    {
      label: <FormattedMessage id="services" />,
      key: "6",
    },
  ];
  const menuProps: any = {
    items,
    onClick: handleMenuClick,
    style: { direction: locale === "en" ? "ltr" : "rtl" },
  };

  return (
    <div
      className={
        isAdmin
          ? "table-header-container-admin"
          : width && width > 53
          ? "table-header-container"
          : "table-header-container-responsive"
      }
    >
      <div
        className={
          isAdmin
            ? "table-header-first-div-admin"
            : width && width > 35
            ? "table-header-first-div"
            : "table-header-first-div-responsive"
        }
        style={{
          gap: isSkelton ? "400px" : width && width > 35 ? "162px" : "",
        }}
      >
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {/* {isAdmin && <img src={Polygon} width={10} height={10} />} */}
            {selectedPage == "requests" ? (
              <div
                style={{ display: "flex", gap: "20px", alignItems: "center" }}
              >
                <span className="table-header-p">{title}</span>
                {/* <img src={Polygon} width={10} height={10} /> */}
              </div>
            ) : (
              isAdmin && (
                <Dropdown menu={menuProps}>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                      justifyContent:
                        locale === "en" ? "flex-start" : "flex-end",
                    }}
                  >
                    <span className="table-header-p">
                      {title === "Employees" ? (
                        <FormattedMessage id="employees" />
                      ) : title === "Requesters" ? (
                        <FormattedMessage id="requesters" />
                      ) : title === "Lists" ? (
                        <FormattedMessage id="lists" />
                      ) : title === "Departments" ? (
                        <FormattedMessage id="departments" />
                      ) : title === "Plants" ? (
                        <FormattedMessage id="plants" />
                      ) : title === "Services" ? (
                        <FormattedMessage id="services" />
                      ) : title === "Home Banner" ? (
                        <FormattedMessage id="home_banner" />
                      ) : title === "Media Center" ? (
                        <FormattedMessage id="media_center" />
                      ) : title === "FAQ's" ? (
                        <FormattedMessage id="faqs" />
                      ) : title === "Mobile App Section" ? (
                        <FormattedMessage id="mobile_app_sections" />
                      ) : title === "Customer Accounts" ? (
                        <FormattedMessage id="customer_accounts" />
                      ) : title === "Notifications" ? (
                        <FormattedMessage id="notifications" />
                      ) : null}
                    </span>
                    <img src={Polygon} width={10} height={10} />
                  </div>
                </Dropdown>
              )
            )}
          </div>
          {isSkelton ? (
            <SkeletonTheme baseColor="#CDCDCD" height={20} width={77}>
              <div>
                <Skeleton />
              </div>
            </SkeletonTheme>
          ) : (
            <p style={{ color: "#535353", marginBottom: "2px" }}>
              {numberOfResults} <FormattedMessage id="results" />
            </p>
          )}
        </div>
        {!(
          localStorage.getItem("selectedSection") === "notification" ||
          localStorage.getItem("selectedSection") === "Section" ||
          localStorage.getItem("selectedSection") === "faqs"
        ) && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: isAdmin ? (isSkelton ? "210px" : "270px") : "324px",
            }}
          >
            <Input
              size="small"
              className="RequestInputStyle"
              placeholder={intl.formatMessage({ id: "search_here" })}
              prefix={<SettingSearchIcon width={24} height={24} />}
              width={212}
              height={48}
              onChange={handleSearch}
            />
            {/* <LinesIcon width={26} height={24} /> */}
          </div>
        )}
      </div>
      {isSkelton && !isAdmin ? (
        ""
      ) : (
        <div
          className={
            isAdmin
              ? "three-icons-div-admin"
              : width && width > 53
              ? "three-icons-div"
              : width && width > 35
              ? "three-icons-div-responsive"
              : "three-icons-div-responsive-2"
          }
        >
          {!isAdmin ||
            (isSkelton && (
              <div
                onClick={() => {
                  setIsCopyClicked && setIsCopyClicked((prev) => !prev);
                  setIsRightSidebar((prev: boolean) => !prev);
                  setLeftWidth && setLeftWidth(isRightSidebar ? "100%" : "65%");
                }}
                style={{
                  width: "44px",
                  height: "44px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor:
                    selectedLayout == "copy" ? "#DAF4E1" : "white",
                  borderRadius: "8px",
                }}
              >
                <CopyCloseIcon width={24} height={24} />
              </div>
            ))}
          {/* <div
            onClick={() => {
              setSelectedLayout && setSelectedLayout("card");
            }}
            style={{
              width: "44px",
              height: "44px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: selectedLayout == "card" ? "#DAF4E1" : "white",
              borderRadius: "8px",
            }}
          >
            <DashboardIcon width={21} height={24} />
          </div> */}
          <div
            onClick={() => {
              setSelectedLayout && setSelectedLayout("table");
            }}
            style={{
              width: "44px",
              height: "44px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: selectedLayout == "table" ? "#DAF4E1" : "white",
              borderRadius: "8px",
            }}
          >
            <OpenCloseSIderIcon width={21} height={21} />
          </div>
        </div>
      )}
    </div>
  );
};

export default TableHeader;
