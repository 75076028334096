import React, { useContext } from "react";
import { Table, Avatar, Tag } from "antd";
import { MoreOutlined, UserOutlined } from "@ant-design/icons";
import type { ColumnsType, TableProps } from "antd/es/table";
import "./style.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { LanguageContext } from "../../context/LanguageContext";

interface ICustomTable {
  isSkelton: boolean;
  data: any;
  columns: any;
  onRow?: any;
  pagination: any;
}

interface DataType {
  key: string;
  name: string;
  position: string;
  section: string;
  number: string;
  tags: string;
}

const onChange: TableProps<DataType>["onChange"] = (
  pagination: any,
  filters: any,
  sorter: any,
  extra: any
) => {};

const CustomTable: React.FC<ICustomTable> = ({
  isSkelton,
  columns,
  data,
  onRow,
  pagination,
}) => {
  const { locale } = useContext(LanguageContext);

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        onChange={onChange}
        style={{ direction: locale === "en" ? "ltr" : "rtl" }}
        pagination={
          pagination
            ? {
                ...pagination,
                className: locale === "en" ? "" : "rtl-pagination", // Apply class for RTL
              }
            : false
        }
        className="DashboardTable"
        onRow={(record: any, rowIndex) => {
          return {
            onClick: (event) => onRow(record),
          };
        }}
      />

      <style>
        {`
        .rtl-pagination .ant-pagination-prev .ant-pagination-item-link {
  transform: rotate(180deg);
}

.rtl-pagination .ant-pagination-next .ant-pagination-item-link {
  transform: rotate(180deg);
}`}
      </style>
    </div>
  );
};

export default CustomTable;
