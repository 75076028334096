import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import "./style.css";
import { Button } from "../../components/Button";
import { TabComponent } from "../../components/Tab";
import { TermAndCondition } from "../../components/ServiceDetailTabs/TermAndCondition";
import { NotificationForm } from "../../components/NotificationDetailForm";
import axios from "axios";
import DragFiles from "../../components/DragFiles/DragFiles";
import SelectImageVideo from "../../components/SelectImageVideo/SelectImageVideo";
import { CustomTable } from "../../components/Table";
import CustomerTab from "../../components/CustomerTab";

interface INotificationDetail {
  locale?: string;
  setNotificationDetailPage?: any;
  isEdit: any;
  setIsEdit: any;
  isEditDetail: any;
  setIsEditDetail: any;
  setRefetch: any;
  setIsLoading: any;
  selectedNotification: any;
}

const NotificationDetail: React.FC<INotificationDetail> = ({
  locale,
  setNotificationDetailPage,
  isEdit,
  setIsEdit,
  isEditDetail,
  selectedNotification,
  setIsEditDetail,
  setRefetch,
  setIsLoading,
}) => {
  // console.log("selectedNotification", selectedNotification);
  const [notificationDetails, setNotificationDetails] = useState(
    isEdit
      ? selectedNotification
      : {
          id: "",
          code: "",
          title: "",
          title_ar: "",
          type: "",
          send_sms: "",
          send_email: "",
          description: "",
          description_ar: "",
          is_active: "",
          send_all: "",
        }
  );
  // console.log("notificationDetails", notificationDetails);
  // const images = selectedNotification?.?.map((image: any) => image.image);
  // const plantImageLists = selectedNotification?.images;
  // console.log("MNK_",images);
  // const [plantImages, setPlantImages] = useState<any>(images);
  const [selectedImages, setSelectedImages] = useState<any>([]);
  // console.log("🚀 ~ selectedImages:", selectedImages);
  const [selectedVideos, setSelectedVideos] = useState<any>([]);
  // console.log("🚀 ~ selectedVideos:", selectedVideos);

  const tabs = [
    {
      event_key: "images",
      title: <FormattedMessage id="images" />,
      component: (
        // <DragFiles
        //   plantImages={plantImages}
        //   setPlantImages={setPlantImages}
        //   isPlantImages={true}
        // />
        <SelectImageVideo
          setSelectedImages={setSelectedImages}
          selectedImages={selectedImages}
          isPlantImages={true}
        />
      ),
    },
    {
      event_key: "videos",
      title: <FormattedMessage id="videos" />,
      component: (
        <SelectImageVideo
          selectedVideos={selectedVideos}
          setSelectedVideos={setSelectedVideos}
          isPlantVideos={true}
        />
        // <DragFiles isPlantVideos={true} />
      ),
    },
    {
      event_key: "users",
      title: <FormattedMessage id="users" />,
      component: <CustomerTab />,
    },
  ];
  const [selectedTab, setSelectedTab] = useState("images");
  const handleTabSelect = (selectedKey: any) => {
    setSelectedTab(selectedKey);
  };

  const handleAddUpdateNotification = () => {
    let data = new FormData();
    data.append("id", notificationDetails?.id);
    data.append("code", notificationDetails?.code);
    data.append("title", notificationDetails?.title);
    data.append("title_ar", notificationDetails?.title_ar);
    data.append("type", notificationDetails?.type);
    data.append("send_sms", notificationDetails?.send_sms ? "1" : "0");
    data.append("send_email", notificationDetails?.send_email ? "1" : "0");
    data.append("description", notificationDetails?.description);
    data.append("description_ar", notificationDetails?.description_ar);
    data.append("is_active", notificationDetails?.is_active ? "1" : "0");

    axios
      .post(
        `${process.env.REACT_APP_PUBLIC_URL}/Notifications/saveNotification`,
        data
      )
      .then((res) => {
        setNotificationDetails((prev: any) => ({
          ...prev,
          id: res?.data?.ref,
        }));
        setNotificationDetailPage(false);
        setRefetch((prev: boolean) => !prev);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleNotificationImageVideo = () => {
    // setIsLoading(true);
    if (selectedImages?.length > 0) {
      const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
      const token: any = sessionStorage.getItem("token");
      let data = new FormData();
      data.append("notification_id", notificationDetails?.id);
      data.append("user_id", userId);
      data.append("token", token);
      for (let i = 0; i < selectedImages?.length; i++) {
        data.append(`images[]`, selectedImages[i]);
      }
      // for (let [key, value] of (data as any).entries()) {
      //   console.log(key, value);
      // }
      // console.log(plantImages);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_PUBLIC_URL}/Notifications/uploadImages`,
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          console.log("res:", res);
          // setRefetch((prev: boolean) => !prev);
          // setNotificationDetailPage(false);
          // setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Container
        className="employee-detail-container"
        style={{ minHeight: "100vh", backgroundColor: "white" }}
      >
        <Row className="header-row">
          {isEdit || isEditDetail ? (
            <Col sm={4} md={6} lg={6} className="back-col">
              <div
                className="redBackBg"
                onClick={() => setNotificationDetailPage(false)}
              >
                <IoIosArrowBack color="red" size={20} />
              </div>
              <p
                className="back-p"
                onClick={() => setNotificationDetailPage(false)}
              >
                <FormattedMessage id="back" />
              </p>
            </Col>
          ) : (
            <Col sm={4} md={6} lg={6} className="back-col">
              <p className="add_new_title"><FormattedMessage id="add_new_notification" /></p>
            </Col>
          )}
          <Col sm={4} md={6} lg={6} className="btns-col">
            {isEdit ? (
              <Button
                title={
                  notificationDetails?.is_active ? <FormattedMessage id="edit_details" /> : <FormattedMessage id="active" />
                }
                className="edit-details-btn"
                hoverClassName=""
                onClick={() => {
                  if (notificationDetails?.is_active) {
                    setIsEdit(false);
                    setIsEditDetail(true);
                  } else {
                    // handleDeactivatePlant();
                  }
                }}
              />
            ) : (
              ""
            )}
            {
              !isEdit ? (
                <Button
                  title={
                    !isEditDetail
                      ? <FormattedMessage id="add" />
                      : isEdit
                      ? <FormattedMessage id="edit_details" />
                      : <FormattedMessage id="save_changes" />
                  }
                  className={"save-changes-btn"}
                  onClick={() => {
                    handleAddUpdateNotification();
                  }}
                />
              ) : (
                ""
              )
              // (
              //   <Button
              //     title={<FormattedMessage id="reset_password" />}
              //     className={"reset-password-btn"}
              //     onClick={() => {}}
              //   />
              // )
            }
            {(isEdit || isEditDetail) &&
              (notificationDetails?.id !== "" || selectedNotification?.id) &&
              (selectedImages?.length > 0 || selectedVideos?.length > 0) && (
                <Button
                  title={<FormattedMessage id="upload_image_video" />}
                  className="edit-details-btn"
                  hoverClassName=""
                  onClick={() => {
                    handleNotificationImageVideo();
                  }}
                />
              )}
            {!isEdit ? (
              <Button
                title={<FormattedMessage id="cancel" />}
                className="deactivate-btn"
                onClick={() => {
                  setIsEdit(true);
                  setNotificationDetailPage(false);
                }}
              />
            ) : (
              ""
              // <Button
              //   title={notificationDetails?.is_active ? "Deactivate" : "Delete"}
              //   className={"deactivate-btn"}
              //   onClick={
              //     () => {}
              //     // notificationDetails?.is_active
              //     //   ? handleDeactivatePlant()
              //     //   : handleDeletePlant()
              //   }
              // />
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <p className="employee-details-p"><FormattedMessage id="notification_details" /></p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12} md={9} lg={12} className="inputs-col">
            <NotificationForm
              isEdit={isEdit}
              notificationDetails={notificationDetails}
              setNotificationDetails={setNotificationDetails}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <div style={{ padding: "10px 10px" }}>
              <p className="employee-title"><FormattedMessage id="notification_text" /></p>
              <input
                type="text"
                value={notificationDetails?.description}
                onChange={(e) =>
                  setNotificationDetails((prev: any) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
                className="employee-form-input"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <div style={{ padding: "10px 10px" }}>
              <p className="employee-title"><FormattedMessage id="notification_text_ar" /></p>
              <input
                type="text"
                value={notificationDetails?.description_ar}
                onChange={(e) =>
                  setNotificationDetails((prev: any) => ({
                    ...prev,
                    description_ar: e.target.value,
                  }))
                }
                className="employee-form-input"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12} className="tabs-col">
            <TabComponent
              selectedTab={selectedTab}
              handleTabSelect={handleTabSelect}
              tabs={tabs}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NotificationDetail;
