import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./style.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { CustomTable } from "../../Table";
import { ColumnsType } from "antd/es/table";

interface IPermissions {
  isEdit?: boolean;
  employeeDetail?: any;
  setEmployeeDetail?: any;
}
const Permissions: React.FC<IPermissions> = ({
  isEdit,
  employeeDetail,
  setEmployeeDetail,
}) => {
  const [permissions, setPermissions] = useState([]);
  const [createdPermission, setCreatedPermission] = useState({
    module_id: "",
    has_rights: "",
    permission: "RO",
  });
  const addPermission = (e: any, record: any) => {
    // console.log(e, record);
    setPermissions((prev: any) =>
      prev.map((p: any) => {
        // console.log("p", p, "record", record);

        if (p.id == record.id) {
          p.is_active = e;
        }
        return p;
      })
    );
    if (e) {
      setEmployeeDetail((prev: any) => ({
        ...prev,
        permissions: [
          ...prev.permissions,
          {
            module_id: record?.id,
            has_rights: e ? 1 : 0,
            permission: createdPermission?.permission,
          },
        ],
      }));

      setCreatedPermission((prev: any) => ({
        ...prev,
        permission: "RO",
      }));
    } else {
      setEmployeeDetail((prev: any) => ({
        ...prev,
        permissions: prev.permissions.filter(
          (permission: any) => permission.module_id !== record.id
        ),
      }));
    }
  };

  const permissionsColumns: ColumnsType<any> = [
    {
      title: <FormattedMessage id="id" />,
      dataIndex: "id",
      render: (text) => (
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
          {text}
        </span>
      ),
      sorter: (a, b) => a.id - b.id,
      sortDirections: ["descend"],
    },
    {
      title: <FormattedMessage id="screen_name" />,
      dataIndex: "name",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <span>{text}</span>
        </div>
      ),

      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend"],
    },
    {
      title: <FormattedMessage id="type_of_permission" />,
      dataIndex: "permissions",
      width: "250px",
      render: (text, record) => {
        return (
          // <select
          //   className="employee-form-input"
          //   value={text}
          //   onChange={(e) => {
          //     setPermissions((prev: any) =>
          //       prev.map((p: any) => {
          //         // console.log("p", p, "record", record);

          //         if (p.id == record.id) {
          //           p.permissions = e.target.value;
          //         }
          //         return p;
          //       })
          //     );
          //     setCreatedPermission((prev) => ({
          //       ...prev,
          //       permission: e.target.value,
          //     }));
          //   }}
          // >
          //   <option value={"RO"}>Read only</option>
          //   <option value={"RW"}>Read write</option>
          // </select>
          <select
            className="employee-form-input"
            value={text}
            onChange={(e) => {
              const newPermissionValue = e.target.value;

              // Update the permissions array with the selected permission
              setPermissions((prev: any) =>
                prev.map((p: any) => {
                  if (p.id === record.id) {
                    p.permissions = newPermissionValue;
                  }
                  return p;
                })
              );

              setCreatedPermission((prev) => ({
                ...prev,
                permission: newPermissionValue,
              }));

              // If the checkbox is active, update employeeDetail with the new permission
              if (record.is_active) {
                setEmployeeDetail((prev: any) => ({
                  ...prev,
                  permissions: prev.permissions.map((permission: any) =>
                    permission.module_id === record.id
                      ? { ...permission, permission: newPermissionValue }
                      : permission
                  ),
                }));
              }
            }}
          >
            <option value="RO">
              <FormattedMessage id="read_only" />
            </option>
            <option value="RW">
              <FormattedMessage id="read_write" />
            </option>
          </select>
        );
      },
      sorter: (a, b) => a.permissions.length - b.permissions.length,
      sortDirections: ["descend"],
    },
    {
      title: <input type="checkbox" />,
      dataIndex: "is_active",
      render: (text, record) => {
        // console.log(record);

        return (
          <input
            checked={text}
            type="checkbox"
            onChange={(e) => addPermission(e.target.checked, record)}
          />
        );
      },
    },
  ];
  useEffect(() => {
    const arr: any = [];
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
    axios
      .get(`${process.env.REACT_APP_PUBLIC_URL}/Admin/getModules?${userId}`)
      .then((res) => {
        // console.log(res);
        const permittedModulesId = employeeDetail?.permissions?.map(
          (p: any) => p.module_id
        );
        // console.log(permittedModulesId);

        const permittedModulesRoles = employeeDetail?.permissions?.map(
          (p: any) => ({ module_id: p.module_id, permissions: p.permission })
        );
        // console.log(permittedModulesRoles);

        res?.data?.data?.modules?.map((d: any) => {
          const permission = permittedModulesRoles.filter(
            (pMR: any) => pMR.module_id == d?.id
          );
          arr.push({
            id: d?.id,
            name: d?.name,
            order_by: d?.order_by,
            modified: d?.modified,
            is_active: permittedModulesId.includes(d?.id),
            permissions: permittedModulesId.includes(d?.id)
              ? permission[0]?.permissions
              : "RO",
          });
        });
        setPermissions(arr);
      });
  }, []);

  return (
    <>
      <Row className="personal-details-row">
        <Col sm={6} md={6} lg={6}>
          <h3 className="detail-style">Screens</h3>
        </Col>
        <Col sm={6} md={6} lg={6} className="details-log-col">
          <h5 className="detail-log">
            <FormattedMessage id="details" />
          </h5>
          <p className="logg">
            <FormattedMessage id="logs" />
          </p>
          <p className="logg">History</p>
        </Col>
      </Row>
      <Row className="personal-details-row2">
        <Col sm={12} md={12} lg={12}>
          <CustomTable
            onRow={() => {}}
            isSkelton={false}
            data={permissions}
            columns={permissionsColumns}
            pagination
          />
        </Col>
      </Row>
    </>
  );
};

export default Permissions;
