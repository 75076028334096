import { Col, Row } from "react-bootstrap";
import "./styles.css";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import { LanguageContext } from "../../context/LanguageContext";

interface IPlantForm {
  isEdit: boolean;
  plantDetail?: any;
  setPlantDetail?: any;
}
const PlantForm: React.FC<IPlantForm> = ({
  isEdit,
  plantDetail,
  setPlantDetail,
}) => {
  const [departments, setDepartments] = useState([]);
  const { locale } = useContext(LanguageContext);

  useEffect(() => {
    const arr: any = [];
    axios
      .post(`${process.env.REACT_APP_PUBLIC_URL}/Departments/list`)
      .then((res) => {
        res.data.data.map((d: any) => {
          arr.push({
            name: d?.department?.name,
            name_ar: d?.department?.name_ar,
            id: d?.department?.id,
          });
        });
        setDepartments(arr);
      });
  }, []);
  const [colors, setColors] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  useEffect(() => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;

    axios
      .post(
        `${process.env.REACT_APP_PUBLIC_URL}/SystemLists/list?user_id=${userId}`
      )
      .then((res) => {
        const colorList = res?.data?.data?.find(
          (d: any) => d.list_name == "Color"
        );
        const categoryList = res?.data?.data?.find(
          (d: any) => d.list_name == "Category"
        );
        // console.log(colorList);
        const arr = colorList?.list_value?.split(",");
        const arr2 = categoryList?.list_value?.split(",");

        setColors(arr);
        setCategories(arr2);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Row>
        <Col sm={12} md={6} lg={2}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="plant_id" />
            </p>
            <input
              disabled
              type="text"
              value={plantDetail?.id}
              className="employee-form-input"
            />
            {/* <p className="emloyee-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={6} lg={5}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="plant_name" />
            </p>
            <input
              type="text"
              disabled={isEdit}
              value={plantDetail?.name}
              onChange={(e) =>
                setPlantDetail((prev: any) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              className="employee-form-input"
            />
            {/* <p className="emloyee-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={6} lg={5}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="plant_name_ar" />
            </p>
            <input
              style={{
                textAlign: "right",
              }}
              type="text"
              disabled={isEdit}
              value={plantDetail?.name_ar}
              onChange={(e) =>
                setPlantDetail((prev: any) => ({
                  ...prev,
                  name_ar: e.target.value,
                }))
              }
              className="employee-form-input"
            />
            {/* <p className="emloyee-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={6} lg={2}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="available_quantity" />
            </p>
            <input
              type="text"
              value={plantDetail?.quantity}
              disabled={isEdit}
              onChange={(e) =>
                setPlantDetail((prev: any) => ({
                  ...prev,
                  quantity: e.target.value,
                }))
              }
              className="employee-form-input"
            />
            {/* <p className="emloyee-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="color" />
            </p>
            <option selected disabled>
              Select Color
            </option>
            <select
              disabled={isEdit}
              className="employee-form-input"
              size={1}
              value={plantDetail?.color}
              onChange={(e) =>
                setPlantDetail((prev: any) => ({
                  ...prev,
                  color: e.target.value,
                }))
              }
            >
              {colors?.map((color: any, i: number) => {
                return (
                  <option key={i} value={color}>
                    {color}
                  </option>
                );
              })}
            </select>
            {/* <p className="emloyee-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="category" />
            </p>
            <option selected disabled>
              Select Category
            </option>
            <select
              disabled={isEdit}
              className="employee-form-input"
              size={1}
              value={plantDetail?.category}
              onChange={(e) =>
                setPlantDetail((prev: any) => ({
                  ...prev,
                  category: e.target.value,
                }))
              }
            >
              {categories?.map((category: any) => {
                return <option value={category}>{category}</option>;
              })}
            </select>
            {/* <p className="emloyee-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={4} lg={4}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="department" />
            </p>
            <select
              disabled={isEdit}
              className="employee-form-input"
              id="departments"
              name="departments"
              size={1}
              value={plantDetail?.department_id}
              onChange={(e) =>
                setPlantDetail((prev: any) => ({
                  ...prev,
                  department_id: e.target.value,
                }))
              }
            >
              <option selected disabled>
                Select Department
              </option>
              {departments?.map((depart: any, i: number) => {
                return (
                  <option key={i} value={depart.id}>
                    {locale === "en" ? depart?.name : depart?.name_ar}
                  </option>
                );
              })}
            </select>
            {/* <p className="emloyee-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={6} lg={2}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="plant_height" />
            </p>
            <input
              type="text"
              value={plantDetail?.plant_height}
              disabled={isEdit}
              onChange={(e) =>
                setPlantDetail((prev: any) => ({
                  ...prev,
                  plant_height: e.target.value,
                }))
              }
              className="employee-form-input"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
export default PlantForm;
