import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./styles.css";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { LanguageContext } from "../../context/LanguageContext";
// import locale from "antd/es/date-picker/locale/en_US";

interface IEmployeeForm {
  isCustomer?: boolean;
  employeeDetail?: any;
  setEmployeeDetail?: any;
  isEdit?: boolean;
}
const EmployeeForm: React.FC<IEmployeeForm> = ({
  isCustomer,
  employeeDetail,
  setEmployeeDetail,
  isEdit,
}) => {
  // console.log("employeeDetail", employeeDetail);

  const [departments, setDepartments] = useState([]);
  const { locale } = useContext(LanguageContext);
  
  useEffect(() => {
    const arr: any = [];
    axios
      .post(`${process.env.REACT_APP_PUBLIC_URL}/Departments/list`)
      .then((res) => {
        res.data.data.map((d: any) => {
          arr.push({
            name: d?.department?.name,
            name_ar: d?.department?.name_ar,
            id: d?.department?.id,
          });
        });
        // setEmployeeDetail((prev: any) => ({
        //   ...prev,
        //   department_id: arr[0]?.id,
        // }));
        setDepartments(arr);
      });
  }, []);
  return (
    <>
      <Row>
        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col">
            <p className="employee-title">
              {isCustomer ? (
                <FormattedMessage id="customer_number" />
              ) : (
                <FormattedMessage id="employee_number" />
              )}
            </p>
            <input
              disabled
              type="text"
              value={employeeDetail?.id}
              className="employee-form-input"
            />
          </div>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="full_name" />
            </p>
            <input
              disabled={isEdit && employeeDetail?.is_active}
              type="text"
              value={employeeDetail?.fullname}
              className="employee-form-input"
              onChange={(e) => {
                setEmployeeDetail((prev: any) => ({
                  ...prev,
                  fullname: e.target.value,
                }));
              }}
            />
          </div>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="full_name_ar" />
            </p>
            <input
              disabled={isEdit}
              type="text"
              style={{ textAlign: "right" }}
              value={employeeDetail?.fullname_ar}
              className="employee-form-input"
              onChange={(e) => {
                setEmployeeDetail((prev: any) => ({
                  ...prev,
                  fullname_ar: e.target.value,
                }));
              }}
            />
          </div>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col">
            <p className="employee-title">
              {isCustomer ? (
                <FormattedMessage id="type" />
              ) : (
                <FormattedMessage id="department" />
              )}
            </p>
            <select
              disabled={isEdit}
              className="employee-form-input"
              id="departments"
              name="departments"
              size={1}
              value={
                employeeDetail?.department_id
                // ? employeeDetail?.department_id
                // : "Select Department"
              }
              onChange={(e) =>
                setEmployeeDetail((prev: any) => ({
                  ...prev,
                  department_id: e.target.value,
                }))
              }
            >
              <option value={""} selected disabled>
                Select Department
              </option>
              {departments?.map((depart: any, i) => {
                return (
                  <option key={i} value={depart.id}>
                    {isCustomer
                      ? "Regular Customer"
                      : `${locale === "en" ? depart?.name : depart?.name_ar}`}
                  </option>
                );
              })}
            </select>
          </div>
        </Col>

        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="mobile_number" />
            </p>
            <input
              disabled={isEdit}
              type="text"
              value={
                isCustomer
                  ? employeeDetail?.mobile_number
                  : employeeDetail?.mobile
              }
              className="employee-form-input"
              onChange={(e) => {
                setEmployeeDetail((prev: any) => ({
                  ...prev,
                  mobile: e.target.value,
                }));
              }}
            />
          </div>
        </Col>

        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="email" />
            </p>
            <input
              disabled={isEdit}
              type="email"
              value={employeeDetail?.email}
              className="employee-form-input"
              onChange={(e) => {
                setEmployeeDetail((prev: any) => ({
                  ...prev,
                  email: e.target.value,
                }));
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
export default EmployeeForm;
