import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
// import { useState } from "react";
import { FormattedMessage } from "react-intl";

import "./style.css";
// import { PersonalDetails ,WorkInformation} from "../../components/EmpDetailTabs/index";
import { ListDetailForm } from "../../components/ListDetail";
import { Button } from "../../components/Button";
import { useState } from "react";
import axios from "axios";
import qs from "qs";
const FormData = require("form-data");
interface IListDetail {
  locale?: string;
  setListDetailPage: any;
  isEdit: any;
  setIsEdit: any;
  isEditDetail: any;
  setIsEditDetail: any;
  selectedList: any;
  setRefetch: any;
  isLoading: boolean;
  setIsLoading: any;
}

const ListDetail: React.FC<IListDetail> = ({
  locale,
  setListDetailPage,
  isEdit,
  isEditDetail,
  setIsEdit,
  setIsEditDetail,
  selectedList,
  setRefetch,
  isLoading,
  setIsLoading,
}) => {
  const [listDetail, setListDetail] = useState(
    isEdit
      ? selectedList
      : {
          id: "",
          name: "",
          name_ar: "",
          value: "",
          value_ar: "",
          is_active: "",
        }
  );

  const handleAddUpdateList = () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("name", listDetail?.name);
    data.append("name_ar", listDetail?.name_ar);
    data.append("value", listDetail?.value);
    data.append("value_ar", listDetail?.value_ar);
    data.append("is_active", 1);
    data.append("id", listDetail?.id);
    axios
      .post(`${process.env.REACT_APP_PUBLIC_URL}/SystemLists/addEdit`, data)
      .then((res) => {
        setIsEdit(true);
        setListDetail((prev: any) => ({
          ...prev,
          id: res?.data?.ref,
        }));
        setRefetch((prev: any) => !prev);
        setListDetailPage(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleDeactivateList = () => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;

    let data = qs.stringify({
      list_id: selectedList?.id,
      status: selectedList?.is_active ? "0" : "1",
      user_id: userId,
    });

    axios
      .post(`${process.env.REACT_APP_PUBLIC_URL}/SystemLists/updateStatus`, data)
      .then((res) => {
        setListDetail((prev: any) => ({
          ...prev,
          is_active: !prev.is_active,
        }));
        setRefetch((prev: boolean) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteList = () => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
    axios
      .post(
        `${process.env.REACT_APP_PUBLIC_URL}/SystemLists/delete/${selectedList?.id}?user_id=${userId}`
      )
      .then((res) => {
        console.log(res);
        setRefetch((prev: boolean) => !prev);
        setListDetailPage(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Container
        className="employee-detail-container"
        style={{ minHeight: "100vh", backgroundColor: "white" }}
      >
        <Row className="header-row">
          {isEdit || isEditDetail ? (
            <Col sm={4} md={6} lg={6} className="back-col">
              <div
                className="redBackBg"
                onClick={() => setListDetailPage(false)}
              >
                <IoIosArrowBack color="red" size={20} />
              </div>
              <p className="back-p" onClick={() => setListDetailPage(false)}>
                <FormattedMessage id="back" />
              </p>
            </Col>
          ) : (
            <Col sm={4} md={6} lg={6} className="back-col">
              <p className="add_new_title"><FormattedMessage id="add_new_list" /></p>
            </Col>
          )}
          <Col sm={4} md={6} lg={6} className="btns-col">
            {isEdit ? (
              <Button
                title={listDetail?.is_active ? <FormattedMessage id="edit_details" /> : <FormattedMessage id="active" />}
                className="edit-details-btn"
                hoverClassName=""
                onClick={() => {
                  if (listDetail?.is_active) {
                    setIsEdit(false);
                    setIsEditDetail(true);
                  } else {
                    handleDeactivateList();
                  }
                }}
              />
            ) : (
              ""
            )}
            {!isEdit ? (
              <Button
                title={
                  !isEditDetail
                    ? <FormattedMessage id="add" />
                    : isEdit
                    ? <FormattedMessage id="edit_details" />
                    : <FormattedMessage id="save_changes" />
                }
                className={"save-changes-btn"}
                onClick={() => {
                  handleAddUpdateList();
                }}
              />
            ) : (
              ""
            )}
            {!isEdit ? (
              <Button
                title={<FormattedMessage id="cancel" />}
                className="deactivate-btn"
                onClick={() => {
                  setIsEdit(true);
                  setListDetailPage(false);
                }}
              />
            ) : (
              <Button
                title={listDetail?.is_active ? <FormattedMessage id="deactivate" /> : <FormattedMessage id="delete" />}
                className={"deactivate-btn"}
                onClick={() =>
                  listDetail?.is_active
                    ? handleDeactivateList()
                    : handleDeleteList()
                }
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <p className="employee-details-p"><FormattedMessage id="list_details" /></p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12} md={12} lg={12} className="inputs-col">
            <ListDetailForm
              isEdit={isEdit}
              selectedList={listDetail}
              setListDetail={setListDetail}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ListDetail;
