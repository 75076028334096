import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

interface ITabs {
  event_key: string;
  title: any;
  component: any;
}
interface ITabComponent {
  selectedTab: string;
  handleTabSelect: any;
  tabs: ITabs[];
}

const TabComponent: React.FC<ITabComponent> = ({
  selectedTab,
  handleTabSelect,
  tabs,
}) => {
  return (
    <Tabs
      id="fill-tab-example"
      className=""
      activeKey={selectedTab}
      onSelect={handleTabSelect}
      defaultActiveKey={tabs[0]?.event_key}
      fill
    >
      {tabs?.map((tab) => (
        <Tab
          disabled={
            tab.event_key == "documents" ||
            tab.event_key == "financial-details" ||
            tab.event_key == "education" ||
            tab.event_key == "leaves"
          }
          key={tab.event_key}
          eventKey={tab.event_key}
          title={tab.title}
        >
          {tab.component}
        </Tab>
      ))}
    </Tabs>
  );
};

export default TabComponent;
