import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./style.css";
import React from "react";

interface IServiceFees {
  serviceDetail: any;
  setServiceDetail: any;
  isEdit: boolean;
}
const ServiceFees: React.FC<IServiceFees> = ({
  serviceDetail,
  setServiceDetail,
  isEdit,
}) => {
  return (
    <div>
      <Row className="personal-details-row">
        <Col sm={6} md={6} lg={6}>
          <h3 className="detail-style">
            <FormattedMessage id="fees" />
          </h3>
        </Col>
        <Col sm={6} md={6} lg={6} className="details-log-col">
          <h5 className="detail-log">
            <FormattedMessage id="details" />
          </h5>
          <p className="logg">
            <FormattedMessage id="logs" />
          </p>
        </Col>
      </Row>
      <Row className="personal-details-row2">
        <Col sm={12} md={3} lg={3}>
          <div style={{ padding: "10px 5px" }}>
            <p className="employee-title">
              <FormattedMessage id="service_fees" />
            </p>
            <input
              disabled={isEdit}
              type="text"
              value={serviceDetail?.fee}
              onChange={(e) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  fee: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
            {/* <p className="employee-form-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={3} lg={3}>
          <div style={{ padding: "10px 5px" }}>
            <p className="employee-title">
              <FormattedMessage id="service_fees_ar" />
            </p>
            <input
              disabled={isEdit}
              type="text"
              value={serviceDetail?.fee_ar}
              onChange={(e) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  fee_ar: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
            {/* <p className="employee-form-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={3} lg={3}>
          <div style={{ padding: "10px 5px" }}>
            <p className="employee-title">
              <FormattedMessage id="work_hours" />
            </p>
            <input
              disabled={isEdit}
              type="text"
              value={serviceDetail?.work_hours}
              onChange={(e) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  work_hours: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
            {/* <p className="employee-form-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={3} lg={3}>
          <div style={{ padding: "10px 5px" }}>
            <p className="employee-title">
              <FormattedMessage id="work_hours_ar" />
            </p>
            <input
              disabled={isEdit}
              type="text"
              value={serviceDetail?.work_hours_ar}
              onChange={(e) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  work_hours_ar: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
            {/* <p className="employee-form-input">write...</p> */}
          </div>
        </Col>
      </Row>
      <Row className="personal-details-row2">
        <Col sm={12} md={3} lg={3}>
          <div style={{ padding: "10px 5px" }}>
            <p className="employee-title">
              <FormattedMessage id="contact_number" />
            </p>
            <input
              disabled={isEdit}
              type="text"
              value={serviceDetail?.contact_no}
              onChange={(e) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  contact_no: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
            {/* <p className="employee-form-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={3} lg={3}>
          <div style={{ padding: "10px 5px" }}>
            <p className="employee-title">
              <FormattedMessage id="total_service_time" />
            </p>
            <input
              disabled={isEdit}
              type="text"
              value={serviceDetail?.total_service_time}
              onChange={(e) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  total_service_time: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
            {/* <p className="employee-form-input">write...</p> */}
          </div>
        </Col>
        {/* <Col sm={12} md={3} lg={3}>
          <div style={{ padding: "10px 5px" }}>
            <p className="employee-title" style={{ paddingTop: "20px" }}></p>
            <select
              disabled={isEdit}
              className="employee-form-input"
              id="days"
              name="days"
              onChange={(e) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  no_of_days: e.target.value,
                }));
              }}
              defaultValue={serviceDetail?.no_of_days}
              size={1}
            >
              <option value={serviceDetail?.no_of_days}>Days</option>
            </select>
            <p className="employee-form-input">write...</p>
          </div>
        </Col> */}
      </Row>
      <Row className="personal-details-row2" style={{ paddingBottom: "10px" }}>
        <Col sm={12} md={12} lg={12}>
          <div style={{ padding: "0px 5px" }}>
            <p className="employee-title">
              <FormattedMessage id="additional_notes" />
            </p>
            <input
              disabled={isEdit}
              type="text"
              value={serviceDetail?.additional_notes}
              onChange={(e) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  additional_notes: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
            {/* <p className="employee-form-input">write...</p> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ServiceFees;
