import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import "./style.css";
import { useState } from "react";
import { Button } from "../Button";
import axios from "axios";
import FormData from "form-data";
import { Loading } from "../../pages/Loading";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

interface ILoginCard {
  locale: string;
  setIsLoading: any;
}
const LoginCard: React.FC<ILoginCard> = ({ locale, setIsLoading }) => {
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({
    username: "",
    password: "",
  });

  const [CaptchaVisible, setCaptchaVisible] = useState<string | boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [captchaReady, setCaptchaReady] = useState(false);

  // const onRecaptchaChange = (token: string | null) => {
  //   setRecaptchaToken(token);
  //   console.log("Captcha token:", token); // You get the token here when user solves the captcha
  // };

  const handleLoginButtonClick = async () => {
    if (!recaptchaToken) {
      Swal.fire({
        icon: "warning",
        title: "Please complete the CAPTCHA",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    setIsLoading(true);
    let data = new FormData();
    data.append("username", loginDetails.username);
    data.append("password", loginDetails.password);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/Admin/verify`,
        data
      );

      // Handle successful login response
      if (response.data.status) {
        if (response?.data?.username == "admin") {
          sessionStorage.setItem("token", response?.data?.token);
          sessionStorage.setItem("userDetails", JSON.stringify(response?.data));
          setIsLoading(false);
          localStorage.setItem("selectedSection", "employee");
          localStorage.setItem("selectedCard", JSON.stringify(0));
          navigate("/");
        } else if (response?.data?.username == "head") {
          setIsLoading(false);
          navigate("/head");
        } else if (response?.data?.username == "employee") {
          setIsLoading(false);
          navigate("/head");
        }
        Swal.fire({
          icon: "success",
          title: "Login Successful",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
        // Perform navigation or token storage here
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid Username or Password",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error: any) {
      Swal.fire({
        icon: "error",
        title: "Error logging in",
        text: error.message,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // const handleLoginButtonClick = async () => {
  //   setIsLoading(true);
  //   let data = new FormData();
  //   data.append("username", loginDetails?.username);
  //   data.append("password", loginDetails?.password);

  //   axios
  //     .post(`${process.env.REACT_APP_PUBLIC_URL}/Admin/verify`, data)
  //     .then((response: any) => {
  //       // console.log(response);

  //       if (response?.data?.username == "admin") {
  //         sessionStorage.setItem("token", response?.data?.token);
  //         sessionStorage.setItem("userDetails", JSON.stringify(response?.data));
  //         setIsLoading(false);
  //         localStorage.setItem("selectedSection", "employee");
  //         localStorage.setItem("selectedCard", JSON.stringify(0));
  //         navigate("/");
  //       } else if (response?.data?.username == "head") {
  //         setIsLoading(false);
  //         navigate("/head");
  //       } else if (response?.data?.username == "employee") {
  //         setIsLoading(false);
  //         navigate("/head");
  //       } else {
  //         setIsLoading(false);
  //         alert("Invalid Username or Password");
  //       }
  //     });
  // };

  function onChange(token: any) {
    setRecaptchaToken(token);
  }

  return (
    <>
      <div
        className="login-card-inner-div"
        style={{ direction: locale === "en" ? "ltr" : "rtl" }}
      >
        <p className="welcome_message">
          <FormattedMessage id="welcome_back" />
        </p>
        <p className="login_message">
          <FormattedMessage id="login" />
        </p>
        <p className="username-p">
          <FormattedMessage id="user_name" />
        </p>
        <input
          type="text"
          className="username-input"
          onChange={(e) => {
            setLoginDetails((prev) => ({ ...prev, username: e.target.value }));
          }}
        />
        <p className="password-p">
          <FormattedMessage id="password" />
        </p>
        <input
          type="password"
          className="username-input width-auto"
          onChange={(e) => {
            setLoginDetails((prev) => ({ ...prev, password: e.target.value }));
          }}
        />
        <div className="remember-me-div">
          <div className="remember-me-inner-div">
            <input
              type="checkbox"
              className="remember-me-input"
              // onChange={(e) => {
              //   setLoginDetails((prev) => ({
              //     ...prev,
              //     rememberMe: e.target.checked,
              //   }));
              // }}
            />
            <p className="remember-me-p">
              <FormattedMessage id="remember_me" />
            </p>
          </div>
          <Button
            onClick={handleLoginButtonClick}
            title={<FormattedMessage id="login" />}
            className={"login-btn"}
            // onClick={() => {
            //   // if (loginDetails.username && loginDetails.password) {
            //   //   setCaptchaVisible(true);
            //   // }
            //   // handleLoginButtonClick();
            // }}
          />
        </div>
        {/* {CaptchaVisible && ( */}
        <ReCAPTCHA
          // sitekey="6Lc1QlwqAAAAAMr0w-k32lmVPv4xgI-OkSsfV7qP"
          sitekey="6LdHV1wqAAAAAO5aflD6UqUXuo70jc_f3vRIt8rD"
          onChange={onChange}
          style={{ marginTop: "8px", marginLeft: "12px" }}
          //   onExpired={() => setRecaptchaToken(null)} // Clear token if expired
          //   onLoad={() => setCaptchaReady(true)}
        />
        {/* // )} */}
        <p
          className="reset-password-p"
          style={{ marginBottom: 0, marginTop: 0 }}
        >
          <FormattedMessage id="forget_your_password" />{" "}
          <a>
            <span className="reset-password-span">
              <FormattedMessage id="reset_password" />
            </span>
          </a>{" "}
          <FormattedMessage id="or_call" />{" "}
          <a href="">
            <span className="helpdesk-span">
              <FormattedMessage id="helpdesk" />
            </span>
          </a>
        </p>
      </div>
    </>
  );
};

export default LoginCard;
