import { Col, Row } from "react-bootstrap";
import { TbWorld } from "react-icons/tb";
import { FormattedMessage } from "react-intl";
import { LanguageContext } from "../../context/LanguageContext";
import { useContext, useState } from "react";
import "./style.css";
import "./style.ar.css";
import { LoginCard } from "../../components/LoginCard";
import logo from "../../assets/images/logo.png";
import loginBg from "../../assets/images/loginBg.png";
import { Loading } from "../Loading";

interface ILogin {}

const Login: React.FC<ILogin> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { locale, setLocale } = useContext(LanguageContext);

  const handleLanguageChange = () => {
    setLocale(locale == "en" ? "ar" : "en");
    sessionStorage.setItem("lang", locale == "en" ? "ar" : "en");
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Row className="login-container" style={{ overflow: "hidden" }}>
          <Col xs={12} md={1} lg={1}></Col>
          <Col className="logo-col " xs={12} md={3} lg={3}>
            <img className="logo" src={logo} />
            <p className="company-name-div">
              <FormattedMessage id="company_name" />{" "}
            </p>
            <p className="employees-portal-div">
              <FormattedMessage id="admin_portal" />
            </p>
          </Col>
          <Col xs={12} md={8} lg={8} style={{ padding: "0px " }}>
            <div
              className="login-card-col"
              style={{
                background: `url(${loginBg})`,
                backgroundSize: "cover",
                backgroundRepeat: "round",
              }}
            >
              <div>
                <div className="login-card-div">
                  <LoginCard locale={locale} setIsLoading={setIsLoading} />
                </div>
                <div className="language-div">
                  <p
                    className="language-p"
                    style={{ cursor: "pointer" }}
                    onClick={handleLanguageChange}
                  >
                    {locale == "en" ? "العربية" : "English"}
                  </p>
                  <TbWorld color="green" size={22} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Login;
