import React, { useState } from "react";
import { FiTrash2, FiUploadCloud } from "react-icons/fi";
import { useDropzone } from "react-dropzone";
import { FormattedMessage } from "react-intl";

const SelectImageVideo = ({
  isPlantImages,
  isPlantVideos,
  selectedImages,
  setSelectedImages,
  selectedVideos,
  setSelectedVideos,
}: any) => {
  const handleDrop = (acceptedFiles: any) => {
    if (isPlantImages) {
      const newImages = acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setSelectedImages([...selectedImages, ...newImages]);
    } else if (isPlantVideos) {
      const newVideos = acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setSelectedVideos([...selectedVideos, ...newVideos]);
    }
  };

  const handleDeleteImage = (index: any) => {
    const updatedImages = selectedImages.filter(
      (_: any, i: any) => i !== index
    );
    setSelectedImages(updatedImages);
  };

  const handleDeleteVideo = (index: any) => {
    const updatedVideos = selectedVideos.filter(
      (_: any, i: any) => i !== index
    );
    setSelectedVideos(updatedVideos);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: isPlantImages
      ? { "image/jpeg": [], "image/png": [], "image/gif": [] }
      : { "video/mp4": [] },
  });

  return (
    <div style={{ background: "#DAF4E1", padding: "15px 15px 10px 15px" }}>
      {(selectedImages?.length > 0 || selectedVideos?.length > 0) && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              padding: "10px 0px",
              color: "#292929",
              fontSize: 24,
              fontFamily: "Alexandria",
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            {isPlantVideos ? "Plant Videos" : "Plant Images"}
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <p
              style={{
                color: "#6E4C3E",
                fontSize: 20,
                fontFamily: "Alexandria",
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              <FormattedMessage id="details" />
            </p>
            <p
              style={{
                color: "#535353",
                fontSize: 20,
                fontFamily: "Alexandria",
                fontWeight: "300",
                wordWrap: "break-word",
              }}
            >
              <FormattedMessage id="logs" />
            </p>
          </div>
        </div>
      )}

      <div style={{ width: "100%", display: "flex" }}>
        <div className="plantcontainer">
          {isPlantImages &&
            selectedImages.map((image: any, index: any) => (
              <div key={index} className="plantcard">
                <img
                  src={image.preview}
                  title="Plant Image"
                  alt="Plant Image"
                  style={{
                    width: "200px",
                    height: "200px",
                    objectFit: "cover",
                  }}
                />
                <div className="plantcontent">
                  <button onClick={() => handleDeleteImage(index)}>
                    <FiTrash2 color="#ff0000" />
                  </button>
                </div>
              </div>
            ))}
          {isPlantVideos &&
            selectedVideos.map((video: any, index: any) => (
              <div key={index} className="plantcard">
                <video
                  src={video.preview}
                  controls
                  style={{
                    width: "200px",
                    height: "200px",
                    objectFit: "cover",
                  }}
                />
                <div className="plantcontent">
                  <button onClick={() => handleDeleteVideo(index)}>
                    <FiTrash2 color="#ff0000" />
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div
        style={{
          border: "1px #A5A5A5 dotted",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "16px",
          borderRadius: 8,
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <FiUploadCloud size={50} color="#3E6E4B" />
        <p
          style={{
            color: "black",
            fontSize: 20,
            fontFamily: "Alexandria",
            fontWeight: "700",
            wordWrap: "break-word",
            marginTop: "20px",
          }}
        >
          <FormattedMessage id="drag_drop_or" />{" "}
          <span
            style={{
              color: "#3E6E4B",
              fontSize: 20,
              fontFamily: "Alexandria",
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            <FormattedMessage id="browse" />
          </span>
        </p>
        <p
          style={{
            textAlign: "center",
            color: "#535353",
            fontSize: 14,
            fontFamily: "Alexandria",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          <FormattedMessage id="supported_formats" />
        </p>
      </div>
    </div>
  );
};

export default SelectImageVideo;
