import { Col, Row } from "react-bootstrap";
import "./style.css";
import { Card } from "../../components/Card";

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import ProgressIcon from "../../assets/svg/ProgressIcon";
import RejectIcon from "../../assets/svg/RejectIcon";
import DoneIcon from "../../assets/svg/DoneIcon";
import StarIcon from "../../assets/svg/StarIcon";
import { DetailCard } from "../../components/DetailCard";
import { ColumnsType } from "antd/es/table";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Tag } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { TableHeader } from "../../components/TableHeader";
import { CustomTable } from "../../components/Table";


interface IEmployeeDashboard {
  setIsRightSidebar: Dispatch<SetStateAction<boolean>>;
  isRightSidebar: boolean;
  isSkelton: boolean;
  setLeftWidth: Dispatch<SetStateAction<string>>;
  leftWidth: string;
  selectedPage: string;
}

const EmployeeDashboard: React.FC<IEmployeeDashboard> = ({
  setIsRightSidebar,
  isRightSidebar,
  isSkelton,
  setLeftWidth,
  leftWidth,
  selectedPage,
}) => {
  console.log(leftWidth?.split("%")[0]);
  const [isCopyClicked, setIsCopyClicked] = useState(false);
  const [selectedLayout, setSelectedLayout] = useState("table");
  const width = parseInt(leftWidth?.split("%")[0]);
  useEffect(() => {
    if (isCopyClicked) {
      setSelectedLayout("copy");
    } else {
      if (width < 53) {
        setSelectedLayout("card");
      } else {
        setSelectedLayout("table");
      }
    }
  }, [width]);

  interface DataType {
    key: string;
    name: string;
    position: string;
    section: string;
    number: string;
    tags: string;
  }
  interface TagProps {
    color: string;
    bgColor: string;
    borderColor: string;
    textColor: string;
  }
  const columns: ColumnsType<DataType> = [
    {
      title: isSkelton ? (
        <SkeletonTheme baseColor="#CDCDCD" width={49} height={20}>
          <div>
            <Skeleton />
          </div>
        </SkeletonTheme>
      ) : (
        "Req no."
      ),
      dataIndex: "number",
      render: (text) =>
        isSkelton ? (
          <SkeletonTheme baseColor="#CDCDCD" width={49} height={20}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) : (
          <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
            {text}
          </span>
        ),
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend"],
    },
    {
      title: "Service name",
      dataIndex: "name",
      render: (text, record) =>
        isSkelton ? (
          <SkeletonTheme baseColor="#CDCDCD" width={140} height={20}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) : (
          <div
            className="avatar-name-container"
            style={{
              display: "flex",
              alignItems: "center",
              wordWrap: "break-word",
            }}
          >
            {/* <Avatar shape="square" size="large" icon={<UserOutlined />} /> */}
            <span>{text}</span>
          </div>
        ),

      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend"],
    },
    {
      title: "Requester name",
      dataIndex: "position",
      render: (text, record) =>
        isSkelton ? (
          <SkeletonTheme baseColor="#CDCDCD" width={167} height={20}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) : (
          <span>{text}</span>
        ),
      sorter: (a, b) => a.position.length - b.position.length,
      sortDirections: ["descend"],
    },
    {
      title: "Emirates ID",
      dataIndex: "section",
      render: (text, record) =>
        isSkelton ? (
          <SkeletonTheme baseColor="#CDCDCD" width={156} height={20}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) : (
          <span>{text}</span>
        ),
      sorter: (a, b) => a.section.length - b.section.length,
      sortDirections: ["descend"],
    },

    {
      title: "Status",
      key: "tags",
      dataIndex: "tags",
      render: (tag: string) => {
        let tagProps: TagProps = {
          color: "",
          bgColor: "white",
          borderColor: "transparent",
          textColor: "black",
        };

        if (tag === "Rejected") {
          tagProps = {
            color: "#C10606",
            bgColor: "#FFF5F5",
            borderColor: "transparent",
            textColor: "#C10606",
          };
        } else if (tag === "Done") {
          tagProps = {
            color: "#24AF0D",
            bgColor: "#F2FFEF",
            borderColor: "transparent",
            textColor: "#24AF0D",
          };
        } else if (tag === "On progress") {
          tagProps = {
            color: "#E0BE08",
            bgColor: "#FCF9E7",
            borderColor: "transparent",
            textColor: "#E0BE08",
          };
        }

        return isSkelton ? (
          <SkeletonTheme baseColor="#CDCDCD" width={90} height={37}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) : (
          <Tag
            color={tagProps.color}
            key={tag}
            style={{
              color: tagProps.textColor,
              backgroundColor: tagProps.bgColor,
              borderColor: tagProps.borderColor,
            }}
          >
            <span style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '90px', height: '37px', fontFamily: 'Alexandria'}}>{tag}</span>
          </Tag>
        );
      },
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend"],
    },
    {
      title: <MoreOutlined className="MoreOutlined" />,
      dataIndex: "more",
      render: () => <MoreOutlined className="MoreOutlined" />,
    },
  ];
  const data = [
    {
      key: "1",
      number: "151231",
      name: "Issuing building p...",
      section: "7841234123412341",
      tags: "Done",
      position: "person name",
    },
    {
      key: "2",
      number: "151231",
      name: "Issuing building p...",
      section: "7841234123412341",
      tags: "Rejected",
      position: "person name",
    },
    {
      key: "3",
      number: "151231",
      name: "Issuing building p...",
      section: "7841234123412341",
      tags: "On progress",
      position: "person name",
    },
    {
      key: "4",
      number: "151231",
      name: "Issuing building p...",
      section: "7841234123412341",
      tags: "Done",
      position: "person name",
    },
  ];
  return (
    <>
      <Col className="employee-dashboard-col">
        <Row>
          <Col
            xs={12}
            md={6}
            lg={
              width > 53
                ? selectedPage == "requests"
                  ? 6
                  : 3
                : width > 35
                ? 6
                : 12
            }
          >
            <Card
              icon={<StarIcon />}
              title="New requests"
              count={54}
              color="var(--primary-normal, #6E4C3E)"
              isSkelton={isSkelton}
            />
          </Col>
          {selectedPage == "requests" ? (
            ""
          ) : (
            <Col xs={12} md={6} lg={width > 53 ? 3 : width > 35 ? 6 : 12}>
              <Card
                icon={<ProgressIcon />}
                title="On progress"
                count={123}
                color="var(--warning-normal, #E0BE08)"
                isSkelton={isSkelton}
              />
            </Col>
          )}
          {selectedPage == "requests" ? (
            ""
          ) : (
            <Col xs={12} md={6} lg={width > 53 ? 3 : width > 35 ? 6 : 12}>
              <Card
                icon={<RejectIcon />}
                title="Rejected"
                count={13}
                color="var(--errors-normal, #C10606)"
                isSkelton={isSkelton}
              />
            </Col>
          )}
          <Col
            xs={12}
            md={6}
            lg={
              width > 53
                ? selectedPage == "requests"
                  ? 6
                  : 3
                : width > 35
                ? 6
                : 12
            }
          >
            <Card
              icon={<DoneIcon />}
              title="Done"
              count={412}
              isSkelton={isSkelton}
              color="var(--success-normal, #24AF0D)"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <TableHeader
              isAdmin={false}
              setLeftWidth={setLeftWidth}
              width={width}
              isSkelton={isSkelton}
              setIsRightSidebar={setIsRightSidebar}
              isRightSidebar={isRightSidebar}
              selectedLayout={selectedLayout}
              setSelectedLayout={setSelectedLayout}
              setIsCopyClicked={setIsCopyClicked}
              isCopyClicked={isCopyClicked}
              title="Requests"
              numberOfResults={1344}
            />
          </Col>
        </Row>
        <Row className="table-row">
          <Col xs={12} md={12} lg={12}>
            <Row>
              {selectedLayout == "table" ? (
                <CustomTable isSkelton={isSkelton} data={data} columns={columns} pagination />
              ) : (
                <DetailCard width={width} />
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default EmployeeDashboard;
