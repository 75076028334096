import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./style.css";
import { Select, Switch, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import { Button } from "../../Button";

interface DataType {
  id: string;
  step_name: string;
  step_name_ar: string;
  responsible_group: string;
  notification_template: string;
  is_notification: string;
  is_active: string;
}

interface IServiceSteps {
  serviceSteps: any;
  setServiceSteps: any;
  handleDeleteStep: any;
  isEdit: boolean;
  handleStepsInputChange: any;
  options: any;
  Notifications: any;
}
const ServiceSteps: React.FC<IServiceSteps> = ({
  isEdit,
  handleStepsInputChange,
  serviceSteps,
  setServiceSteps,
  handleDeleteStep,
  options,
  Notifications,
}) => {
  // console.log("serviceSteps", serviceSteps);
  const { Option } = Select;
  const columns: ColumnsType<DataType> = [
    {
      title: <FormattedMessage id="id" />,
      dataIndex: "id",
      render: (text) => (
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
          {text}
        </span>
      ),
      sorter: (a, b) => a.id?.length - b.id?.length,
      sortDirections: ["descend"],
    },
    {
      title: <FormattedMessage id="step_name" />,
      dataIndex: "step_name",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            wordWrap: "break-word",
          }}
        >
          {/* <Avatar shape="square" size="large" icon={<UserOutlined />} /> */}
          <input
            disabled={isEdit}
            className="employee-form-input"
            type="text"
            placeholder="Enter Step Name"
            value={text}
            onChange={(e) =>
              handleStepsInputChange(e.target.value, "step_name", record)
            }
          />
        </div>
      ),

      sorter: (a, b) => a.step_name?.length - b.step_name?.length,
      sortDirections: ["descend"],
    },
    {
      title: <FormattedMessage id="step_name_ar" />,
      dataIndex: "step_name_ar",
      render: (text, record) => (
        <input
          disabled={isEdit}
          className="employee-form-input"
          placeholder="Enter Step Name Ar"
          style={{ textAlign: "right" }}
          type="text"
          value={text}
          onChange={(e) =>
            handleStepsInputChange(e.target.value, "step_name_ar", record)
          }
        />
      ),
      sorter: (a, b) => a.step_name_ar?.length - b.step_name_ar?.length,
      sortDirections: ["descend"],
    },
    {
      title: <FormattedMessage id="responsible_group" />,
      dataIndex: "responsible_group",
      render: (text, record) => (
        <>
          {/* <SearchSelect
            options={options}
            selectedOptions={selectedOptions}
            onOptionSelect={handleOptionSelect}
          /> */}
          <Select
            showSearch
            disabled={isEdit}
            style={{
              width: "100%",
              height: "42px",
              background: "#F6F6F6",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "normal",
              border: "none",
            }}
            value={text}
            onChange={(e: any) =>
              handleStepsInputChange(e, "responsible_group", record)
            }
            placeholder="select Group"
            optionFilterProp="children"
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {options?.data?.map((data: any, i: any) => (
              <Option key={i} value={data.id}>
                {data?.name}
              </Option>
            ))}
          </Select>
          {/* <input
            disabled={isEdit}
            className="employee-form-input"
            placeholder="Enter Group"
            type="text"
            value={text}
            onChange={(e) =>
              handleStepsInputChange(
                e.target.value,
                "responsible_group",
                record
              )
            }
          /> */}
        </>
      ),
      sorter: (a, b) =>
        a.responsible_group?.length - b.responsible_group?.length,
      sortDirections: ["descend"],
    },
    {
      title: <FormattedMessage id="notifications" />,
      dataIndex: "notification_id",
      render: (text, record) => (
        // <input
        //   disabled={isEdit}
        //   className="employee-form-input"
        //   type="text"
        //   placeholder="Enter Template"
        //   value={text}
        //   onChange={(e) =>
        //     handleStepsInputChange(
        //       e.target.value,
        //       "notification_template",
        //       record
        //     )
        //   }
        // />
        <Select
          disabled={isEdit}
          style={{
            width: "100%",
            height: "42px",
            background: "#F6F6F6",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "normal",
            border: "none",
          }}
          value={text}
          // disabled={isEdit}
          //   className="employee-form-input"
          //   type="text"
          //   placeholder="Enter Template"
          //   onChange={(e) =>
          //     handleStepsInputChange(
          //       e.target.value,
          //       "notification_template",
          //       record
          //     )
          onChange={(e: any) => {
            // console.log(e)
            handleStepsInputChange(e, "notification_id", record);
          }}
          placeholder="select Group"
          options={Notifications?.data?.map((data: any) => ({
            value: data?.id,
            label: data?.title,
          }))}
        />
      ),
      sorter: (a, b) =>
        a.notification_template?.length - b.notification_template?.length,
      sortDirections: ["descend"],
    },
    {
      title: (
        <div>
          {/* <Switch onChange={() => {}} /> */}
          <FormattedMessage id="notifications" />
        </div>
      ),
      dataIndex: "is_notification",
      render: (text, record) => (
        <span>
          <Switch
            onChange={(checked) =>
              handleStepsInputChange(
                checked ? "Active" : "De-active",
                "is_notification",
                record
              )
            }
          />{" "}
          {text}
        </span>
      ),
    },

    {
      title: (
        <div>
          {/* <Switch onChange={() => {}} /> */}
          <FormattedMessage id="status" />
        </div>
      ),
      dataIndex: "is_active",
      render: (text, record) => {
        return (
          <span>
            <Switch
              checked={text === true ? true : undefined}
              onChange={(checked) =>
                handleStepsInputChange(
                  checked ? "Active" : "De-active",
                  "is_active",
                  record
                )
              }
            />{" "}
            {/* {text} */}
          </span>
        );
      },
    },
    {
      title: <MoreOutlined className="MoreOutlined" />,
      dataIndex: "more",
      render: (text, record) => (
        <DeleteOutlined
          onClick={() => handleDeleteStep(record)}
          color="red"
          className="MoreOutlined"
        />
      ),
    },
  ];
  const data: any = [
    // {
    //   id: "151231",
    //   step_name: "Step Name",
    //   step_name_ar: "محمد أحمد علي",
    //   responsibleGroup: "Customer Services",
    //   notifications: "Template 1",
    //   notifications2: "Didactive",
    //   status: "Didactive",
    // },
    // {
    //   id: "151231",
    //   step_name: "Step Name",
    //   step_name_ar: "محمد أحمد علي",
    //   responsibleGroup: "Customer Services",
    //   notifications: "Template 1",
    //   notifications2: "Didactive",
    //   status: "Didactive",
    // },
    // {
    //   id: "151231",
    //   step_name: "Step Name",
    //   step_name_ar: "محمد أحمد علي",
    //   responsibleGroup: "Customer Services",
    //   notifications: "Template 1",
    //   notifications2: "Didactive",
    //   status: "Didactive",
    // },
  ];
  const addNewObject = () => {
    const newObject = {
      id: serviceSteps?.length + 1,
      step_name: "",
      step_name_ar: "",
      responsible_group: "",
      notification_template: "",
      is_notification: "De-active",
      is_active: "De-active",
    };

    // Update the state by spreading the existing data and adding the new object
    setServiceSteps([...serviceSteps, newObject]);
  };
  return (
    <div>
      <Row className="personal-details-row">
        <Col sm={6} md={6} lg={6}>
          <h3 className="detail-style">
            <FormattedMessage id="steps" />
          </h3>
        </Col>
        <Col sm={6} md={6} lg={6} className="details-log-col">
          <h5 className="detail-log">
            <FormattedMessage id="details" />
          </h5>
          <p className="logg">
            <FormattedMessage id="logs" />
          </p>
        </Col>
      </Row>
      <Row className="personal-details-row2">
        <Col sm={12} md={12} lg={12}>
          <div style={{ padding: "10px 5px" }}>
            <Table
              columns={columns}
              dataSource={serviceSteps}
              pagination={false}
            />
          </div>
        </Col>
      </Row>
      <Row className="personal-details-row2">
        <Col sm={12} md={12} lg={12}>
          <Button
            className="add_new_field_btn"
            title={<FormattedMessage id="add_new_step" />}
            onClick={() => addNewObject()}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ServiceSteps;
