import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./styles.css";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Switch } from "antd";
import { LanguageContext } from "../../context/LanguageContext";

interface IDepartmentForm {
  departmentDetail: any;
  setDepartmentDetail: any;
  isEdit: any;
}
const DepartmentForm: React.FC<IDepartmentForm> = ({
  isEdit,
  departmentDetail,
  setDepartmentDetail,
}) => {
  const [employees, setEmployees] = useState([]);
  const { locale } = useContext(LanguageContext);

  useEffect(() => {
    // console.log(departmentDetail);

    axios
      .post(`${process.env.REACT_APP_PUBLIC_URL}/Employees/list`)
      .then((response: any) => {
        const arr: any = [];
        response?.data?.data?.map((d: any) => {
          arr.push(d);
        });
        setEmployees(arr);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Row>
        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="department_number" />
            </p>
            <input
              disabled
              type="text"
              className="employee-form-input"
              value={departmentDetail?.id}
              onChange={(e) =>
                setDepartmentDetail((prev: any) => ({
                  ...prev,
                  dept_code: e.target.value,
                }))
              }
            />
          </div>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="department_name" />
            </p>
            <input
              disabled={isEdit && departmentDetail?.is_active}
              type="text"
              className="employee-form-input"
              value={departmentDetail?.name}
              onChange={(e) =>
                setDepartmentDetail((prev: any) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
            />
          </div>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="department_name_ar" />
            </p>
            <input
              style={{ textAlign: "right" }}
              disabled={isEdit && departmentDetail?.is_active}
              type="text"
              className="employee-form-input"
              value={departmentDetail?.name_ar}
              onChange={(e) =>
                setDepartmentDetail((prev: any) => ({
                  ...prev,
                  name_ar: e.target.value,
                }))
              }
            />
          </div>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="head_of_department" />
            </p>
            <select
              disabled={isEdit && departmentDetail?.is_active}
              className="employee-form-input"
              size={1}
              onChange={(e) =>
                setDepartmentDetail((prev: any) => ({
                  ...prev,
                  employee_id: e.target.value,
                }))
              }
              placeholder="Select Department"
              value={departmentDetail?.employee_id}
            >
              {employees?.map((employee: any, i: any) => {
                return (
                  <option key={i} value={employee?.employee?.id}>
                    {locale === "en"
                      ? employee?.employee?.fullname
                      : employee?.employee?.fullname_ar}
                  </option>
                );
              })}
            </select>
          </div>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="mobile_number" />
            </p>
            <input
              value={departmentDetail?.mobile_no}
              type="text"
              className="employee-form-input"
              onChange={(e) =>
                setDepartmentDetail((prev: any) => ({
                  ...prev,
                  mobile_no: e.target.value,
                }))
              }
              disabled={isEdit && departmentDetail?.is_active}
            />
          </div>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="email" />
            </p>
            <input
              value={departmentDetail?.email}
              type="text"
              className="employee-form-input"
              onChange={(e) =>
                setDepartmentDetail((prev: any) => ({
                  ...prev,
                  email: e.target.value,
                }))
              }
              disabled={isEdit && departmentDetail?.is_active}
            />
          </div>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="location_coordinates" />
            </p>
            <input
              disabled={isEdit && departmentDetail?.is_active}
              type="text"
              className="employee-form-input"
              value={departmentDetail?.coordinates}
              onChange={(e: any) =>
                setDepartmentDetail((prev: any) => ({
                  ...prev,
                  coordinates: e.target.value,
                }))
              }
            />
          </div>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              // alignItems: "center",
              padding: "10px",
              gap: "10px",
            }}
          >
            {/* <Switch
              disabled={isEdit && departmentDetail?.is_active}
              checked={departmentDetail?.is_agriculture}
              onChange={(event) => {
                setDepartmentDetail((prev: any) => ({
                  ...prev,
                  is_agriculture: !prev?.is_agriculture,
                }));
              }}
            /> */}
            <Switch
              disabled={isEdit && departmentDetail?.is_active}
              checked={departmentDetail?.is_agriculture === "1"}
              onChange={(event) => {
                setDepartmentDetail((prev: any) => ({
                  ...prev,
                  is_agriculture: prev?.is_agriculture === "1" ? "0" : "1",
                }));
              }}
            />
            <p className="employee-title">
              <FormattedMessage id="is_agriculture" />
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default DepartmentForm;
