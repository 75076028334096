import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./styles.css";

interface IListDetailForm {
  isEdit?: boolean;
  selectedList?: any;
  setListDetail?: any;
}

const ListDetailForm: React.FC<IListDetailForm> = ({
  selectedList,
  isEdit,
  setListDetail,
}) => {
  console.log(selectedList);

  return (
    <Col>
      <Row>
        <Col sm={12} lg={2} md={12}>
          <div style={{ padding: "5px" }}>
            <p className="employee-title">
              <FormattedMessage id="list_id" />
            </p>
            <input
              type="text"
              value={selectedList?.id}
              disabled
              className="employee-form-input"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="list_name" />
            </p>
            <input
              type="text"
              value={selectedList?.name}
              disabled={isEdit}
              onChange={(e) => {
                setListDetail((prev: any) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="list_name_ar" />
            </p>
            <input
              type="text"
              value={selectedList?.name_ar}
              disabled={isEdit}
              onChange={(e) => {
                setListDetail((prev: any) => ({
                  ...prev,
                  name_ar: e.target.value,
                }));
              }}
              className="employee-form-input"
              style={{ textAlign: "right" }}
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="value" />
            </p>
            <input
              type="text"
              value={selectedList?.value}
              disabled={isEdit}
              onChange={(e) => {
                setListDetail((prev: any) => ({
                  ...prev,
                  value: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="value_ar" />
            </p>
            <input
              type="text"
              value={selectedList?.value_ar}
              disabled={isEdit}
              onChange={(e) => {
                setListDetail((prev: any) => ({
                  ...prev,
                  value_ar: e.target.value,
                }));
              }}
              className="employee-form-input"
              style={{ textAlign: "right" }}
            />
          </div>
        </Col>
      </Row>
    </Col>
  );
};
export default ListDetailForm;
