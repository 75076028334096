import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./styles.css";
import { Select, Switch } from "antd";

interface INotificationForm {
  isEdit: boolean;
  notificationDetails: any;
  setNotificationDetails: any;
}
const NotificationForm: React.FC<INotificationForm> = ({
  isEdit,
  notificationDetails,
  setNotificationDetails,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <Row>
        <Col sm={12} md={6} lg={2}>
          <div className="inputs-label-col">
            <p className="employee-title"><FormattedMessage id="notification_id" /></p>
            <input
              type="text"
              value={notificationDetails?.id}
              disabled
              className="employee-form-input"
            />
          </div>
        </Col>
        <Col sm={12} md={6} lg={2}>
          <div className="inputs-label-col">
            <p className="employee-title"><FormattedMessage id="notification_code" /></p>
            <input
              type="text"
              value={notificationDetails?.code}
              onChange={(e) =>
                setNotificationDetails((prev: any) => ({
                  ...prev,
                  code: e.target.value,
                }))
              }
              className="employee-form-input"
            />
          </div>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col">
            <p className="employee-title"><FormattedMessage id="notification_title" /></p>
            <input
              type="text"
              value={notificationDetails?.title}
              onChange={(e) =>
                setNotificationDetails((prev: any) => ({
                  ...prev,
                  title: e.target.value,
                }))
              }
              className="employee-form-input"
            />
          </div>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col">
            <p className="employee-title"><FormattedMessage id="notification_title_ar" /></p>
            <input
              type="text"
              value={notificationDetails?.title_ar}
              onChange={(e) =>
                setNotificationDetails((prev: any) => ({
                  ...prev,
                  title_ar: e.target.value,
                }))
              }
              className="employee-form-input"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col">
            <p className="employee-title"><FormattedMessage id="type" /></p>
            <select
              className="employee-form-input"
              id="departments"
              name="departments"
              size={1}
              value={notificationDetails?.type}
              onChange={(e) =>
                setNotificationDetails((prev: any) => ({
                  ...prev,
                  type: e.target.value,
                }))
              }
            >
              <option value="">Select Type</option>
              <option value="General Notification">General Notification</option>
              <option value="Service Step">Service Step</option>
            </select>
          </div>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col" style={{ marginTop: "40px" }}>
            <Switch
              checked={notificationDetails?.send_sms}
              onChange={(checked, event) =>
                setNotificationDetails((prev: any) => ({
                  ...prev,
                  send_sms: checked,
                }))
              }
            />{" "}
            <FormattedMessage id="sms" />
          </div>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col" style={{ marginTop: "40px" }}>
            <Switch
              checked={notificationDetails?.send_email}
              onChange={(checked, event) =>
                setNotificationDetails((prev: any) => ({
                  ...prev,
                  send_email: checked,
                }))
              }
            />{" "}
            <FormattedMessage id="email" />
          </div>
        </Col>
        {/* <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col" style={{ marginTop: "40px" }}>
            <input
              type="radio"
              name="send"
              id="all"
              disabled={isEdit}
              onChange={(e) => {
                setNotificationDetails((prev: any) => ({
                  ...prev,
                  send_all: e.target.checked && 1,
                }));
              }}
              //  onChange={(checked:any, event:any) =>
              //   setNotificationDetails((prev: any) => ({
              //     ...prev,
              //     send_all: checked,
              //   }))
              // }
            />
            <label
              htmlFor="all"
              style={{ marginLeft: "5px", fontWeight: "bold" }}
            >
              {" "}
              Send to All
            </label>
          </div>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col" style={{ marginTop: "40px" }}>
            <input 
            disabled={isEdit} 
            type="radio" 
            name="send" 
            id="spicific" 
            onChange={(e) => {
              setNotificationDetails((prev: any) => ({
                ...prev,
                send_all: e.target.checked && 0,
              }));
            }}
            />
            <label
              htmlFor="spicific"
              style={{ marginLeft: "5px", fontWeight: "bold" }}
            >
              Select Specific
            </label>
          </div>
        </Col>
        {notificationDetails?.send_all === 0 && (

        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col" style={{ marginTop: "40px" }}>
          <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                    height: "auto",
                    background: "#F6F6F6",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                  placeholder="select Customer"
                  disabled={isEdit}
                  
                  // // className="employee-form-input"
                  // value={employeeDetail?.position_id}
                  // onChange={(selectedOption) => {
                  //   setEmployeeDetail((prev: any) => ({
                  //     ...prev,
                  //     position_id: selectedOption,
                  //   }));
                  // }}
                  // options={positions?.map((position: any) => ({
                  //   value: position.id,
                  //   label: position.name,
                  // }))}
                >
                  <option value={"hello"}>hello</option>
                  <option value={"World"}>World</option>
                  <option value={"Tech"}>Tech</option>
                  </Select>
          </div>
        </Col>
        )} */}
      </Row>
    </div>
  );
};
export default NotificationForm;
